import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET INVOICES
export const getInvoicesService = createAsyncThunk(
  "finance/getInvoicesService",
  async ({ page, search_value }, thunkAPI) => {
    let url = `${URLS.getInvoicesURL}?page=${page}`;
    if (search_value) {
      url += `&search_value=${search_value}`;
    }
    try {
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PAYMENT
export const createPaymentService = createAsyncThunk(
  "finance/createPaymentService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createPaymentURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE STATEMENT
export const createStatementsService = createAsyncThunk(
  "finance/createStatementsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createStatementsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PAYMENT RECEPIPT
export const createPaymentReceiptService = createAsyncThunk(
  "finance/createPaymentReceiptService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createPaymentReceiptURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMattersByClient = createAsyncThunk(
  "finance/getMattersByClient",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`/get_matters_by_client/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createFinanceFilterService = createAsyncThunk(
  "finance/createFinanceFilterService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createFilterInvoivces, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const exportStatementsService = createAsyncThunk(
  "finance/exportStatementsService",
  async ({ params, page }, thunkAPI) => {
    console.log("params");
    console.log(params);
    console.log(page);
    try {
      const response = await axios.get(URLS.exportStatementsURL, {
        params: { ...params },
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getImportExportsService = createAsyncThunk(
  "finance/getImportExportsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getImportExportsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPaymentReceiptsListService = createAsyncThunk(
  "finance/getPaymentReceiptsListService",
  async ({ params, page }, thunkAPI) => {
    try {
      let url = `${URLS.getPaymentReceiptsListURL}`;
      if (page !== undefined && page !== null) {
        url += `?page=${encodeURIComponent(page)}`;
      }
      if (params) {
        Object.keys(params).forEach((key) => {
          if (params[key] !== undefined && params[key] !== null) {
            url += url.includes("?")
              ? `&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
              : `?${encodeURIComponent(key)}=${encodeURIComponent(
                  params[key]
                )}`;
          }
        });
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getGeneralExpensesService = createAsyncThunk(
  "finance/getGeneralExpensesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getGeneralExpensesURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createGeneralExpensesService = createAsyncThunk(
  "finance/createGeneralExpensesService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createGeneralExpensesURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => send Email To Client
export const sendEmailToClientService = createAsyncThunk(
  "finance/sendEmailToClientService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.sendToClientURL}/${id}/send_to_client/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCustudyExpenseService = createAsyncThunk(
  "finance/getCustudyExpenseService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/custody_expenses_for_employee/${data?.id}/get_all/`,
        {
          params: {
            ...data,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createExpensesCustudy = createAsyncThunk(
  "finance/createExpensesCustudy",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post(`/custody_expenses/create/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>     GET EXPENS STATMENTS

export const expenseStatementsService = createAsyncThunk(
  "finance/expenseStatementsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`/expense-statements/get_all/`, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     CREATE EXPENS MATTER
export const createExpenseMatterService = createAsyncThunk(
  "finance/createExpenseMatterService",
  async (data, thunkAPI) => {
    try {
      console.log("data ===>", data);
      const response = await axios.post(`/create_expense_matter/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     CHECK EXPENS IF EXSIST
export const checkExpense = createAsyncThunk(
  "finance/checkExpense",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/check_if_expenses_exists_for_matter/`,
        {
          params: {
            ...data?.data,
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createStatementsIndividualService = createAsyncThunk(
  "finance/createStatementsIndividualService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        `/expense-statements/create-for-individual/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>     CREATE EXPENS STATMENTS
export const createStatementsCompanyService = createAsyncThunk(
  "finance/createStatementsCompanyService",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post(
        `/expense-statements/create-for-company/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getExpensesMattersService = createAsyncThunk(
  "finance/getExpensesOppService",
  async ({ id, page }, thunkAPI) => {
    try {
      let url = `/expense-statements/${id}/expense-matters/get_all/?page=${page}`;

      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAllContractsService = createAsyncThunk(
  "finance/getAllContractsService",
  async (params, thunkAPI) => {
    console.log("params");
    try {
      const response = await axios.get(URLS.getAllContractsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  HAND OVER TO THE RESPONSIBLE
export const handOverRequestService = createAsyncThunk(
  "finance/handOverRequestService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(
        `/expense-statements/${id}/hand-over-to-the-responsible/`,
        null
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAssetsService = createAsyncThunk(
  "finance/getAssetsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getAssetsURL}`, {
        params: { ...params, page_size: 10 },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAssetsTypeService = createAsyncThunk(
  "finance/getAssetsTypeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getAssetsTypeURL}` /*, {
        params: { ...params, page_size: 10 },
      }*/
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createAssetService = createAsyncThunk(
  "finance/createAssetService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${URLS.createAssetURL}`, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  RECIVE REQUEST
export const ReciveRequestService = createAsyncThunk(
  "debtCollection/handOverRequestService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(
        `/expense-statements/${id}/receive/`,
        null
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET INVOICE FOR CONTRACT
export const getInvoiceForContractService = createAsyncThunk(
  "finance/getInvoiceForContractService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getInvoiceForContractURL}/${params?.id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET ACCOUNTS SERVICE
export const getAccountsService = createAsyncThunk(
  "finance/getAccountsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.accountsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//CREATE => CREATE ACCOUNTS SERVICE
export const createAccountService = createAsyncThunk(
  "finance/createAccountService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createAccountsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET TREE ACOUNTS SERVICE
export const getTreeAcountsService = createAsyncThunk(
  "finance/getTreeAcountsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.treeAccountsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET JOURNAL SERVICE
export const getJournalService = createAsyncThunk(
  "finance/getJournalService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.journalURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//UPDATE => UPDATE JOURNAL STATMENT SERVICE
export const updateJournalStatentService = createAsyncThunk(
  "finance/updateJournalStatentService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.journalURL}${params?.id}/update-statement/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET FROM ACCOUNT
export const getFromAccountService = createAsyncThunk(
  "finance/getFromAccountService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.fromAccountURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET JOURNAL SERVICE
export const generateReportService = createAsyncThunk(
  "finance/generateReportService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.generateReportURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//GET => GET FROM ACCOUNT
export const getTypeExpenseService = createAsyncThunk(
  "finance/getTypeExpenseService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.typeExpenseURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET DEPTOR SERVICE
export const getDebtorsService = createAsyncThunk(
  "finance/getDebtorsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.debtorsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => POST DEPTOR SERVICE
export const createDebtorsCollectService = createAsyncThunk(
  "finance/createDebtorsCollectService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createDebtorsCollectURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>     CREATE NewType
export const createTypeExpenseService = createAsyncThunk(
  "finance/createTypeExpenseService",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createTypeURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => CREATE DEPTOR SERVICE
export const createDeptorService = createAsyncThunk(
  "finance/createDeptorService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createDebtorsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//GET => GET Type Asset
export const getTypeAssetService = createAsyncThunk(
  "finance/getTypeAssetService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.typeAssetURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET REVERSE SERVICE
export const getReverseService = createAsyncThunk(
  "finance/getReverseService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.reserveURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => GET MOVMENT REVERSE SERVICE
export const getMovementsReverseService = createAsyncThunk(
  "finance/getMovementsReverseService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.reserveURL}${params?.id}/movements/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//CREATE => CREATE REVERSE SERVICE
export const createReverseService = createAsyncThunk(
  "finance/createReverseService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createReserveURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => POST WITHDRAW REVERSE SERVICE
export const createWithdrawReverseService = createAsyncThunk(
  "finance/createWithdrawReverseService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.withDrawReserveURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//

//POST => COLLECT DEPTOR SERVICE
export const collectDeptorService = createAsyncThunk(
  "finance/collectDeptorService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.collectURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMattersAndContractByClient = createAsyncThunk(
  "finance/getMattersAndContractByClient",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `/get_matter_and_contract_billable_for_client/${id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMattersWithoutContractByClient = createAsyncThunk(
  "finance/getMattersWithoutContractByClient",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/get_matter_without_contract_for_client/${params.id}`,
        {
          params: {
            search_value: params?.search_value,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET => GET EMPLOYEE FACILITY
export const getEmployeeFacilityService = createAsyncThunk(
  "finance/getEmployeeFacilityService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.employeeFacilityURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET STATUS CHOICE
export const getStatusChoiceService = createAsyncThunk(
  "finance/getStatusChoiceService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.statusChoiceURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET TYPE CHOICE
export const getTypeChoiceService = createAsyncThunk(
  "finance/getTypeChoiceService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.typeChoiceURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET PAYMENT TYPE CHOICE SERVICE
export const getPaymentTypeChoiceService = createAsyncThunk(
  "finance/getPaymentTypeChoiceService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.paymentTypeChoiceURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST => CREATE EMPLOYEE LIABILITY
export const createEmployeeLiabilitiesService = createAsyncThunk(
  "finance/createEmployeeLiabilitiesService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS?.employeeLiabilitiesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT => EDIT EMPLOYEE LIABILITY BY ID
export const getEmployeeLiabilitieByIdService = createAsyncThunk(
  "finance/getEmployeeLiabilitieByIdService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS?.employeeFacilityURL}${params?.id}/show/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT => EDIT EMPLOYEE LIABILITY
export const editEmployeeLiabilitiesService = createAsyncThunk(
  "finance/editEmployeeLiabilitiesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS?.employeeFacilityURL}${params?.id}/update/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE => DELETE EMPLOYEE LIABILITY
export const deleteEmployeeLiabilitiesService = createAsyncThunk(
  "finance/deleteEmployeeLiabilitiesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS?.employeeFacilityURL}${params?.id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT => PUT EMPLOYEE LIABILITY
export const collectEmployeeLiabilitiesService = createAsyncThunk(
  "finance/collectEmployeeLiabilitiesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS?.employeeFacilityURL}${params?.id}/collect/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>GET SALARY SERVICE
export const getSalaryService = createAsyncThunk(
  "finance/getSalaryService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.salariesURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PPST =>CREATE SALARY SERVICE
export const createSalaryService = createAsyncThunk(
  "finance/createSalaryService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS?.payrollSalariesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>GET TOTAL SALARY SERVICE
export const getTotalSalaryForPayrollService = createAsyncThunk(
  "finance/getTotalSalaryForPayrollService",
  async (params, thunkAPI) => {
    console.log("params");
    console.log(params);
    try {
      const response = await axios.get(URLS?.totalSalariesForPayrollURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET BOUNSES CHOICE SERVICE
export const getBounsesChoiceService = createAsyncThunk(
  "finance/getBounsesChoiceService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.statusChoiceBonusURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET BOUNSES SERVICE
export const getBounsesService = createAsyncThunk(
  "finance/getBounsesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.bonusesURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// CREATE => CREATE BOUNSES SERVICE
export const creteBounsesService = createAsyncThunk(
  "finance/creteBounsesService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS?.createBonusURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// EDIT => EDIT BOUNSES SERVICE
export const editBounsesService = createAsyncThunk(
  "finance/editBounsesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS?.bonusesURL}${params?.id}/update/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE => DELETE BOUNSES SERVICE
export const deleteBounsesService = createAsyncThunk(
  "finance/deleteBounsesService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS?.bonusesURL}${id}/delete/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// EDIT => PUT BOUNSES SERVICE
export const changeBounsesStatusService = createAsyncThunk(
  "finance/changeBounsesStatusService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS?.bonusesURL}${params?.id}/change-status/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GENERATE REPORT SERVICE

export const generateSalaryReportService = createAsyncThunk(
  "finance/generateSalaryReportService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS?.generateSalaryReportURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
