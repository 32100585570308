import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Box, Stack, Drawer, Divider, Button, Typography } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import { NAV } from "../../../config";
// components
import { NavSectionVertical } from "../../../components/nav-section";
//
import { navConfig, accountConfig, matterConfig, hrConfig } from "./config";
import { useLocales } from "../../../locales";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";

import { useNavigate } from "react-router-dom";
// ---------------------------------------------------------------------

const logoutSx = {
  border: "1px solid #6A8490",
  borderRadius: "2px",
  height: "38px",
  width: "80%",
  display: "flex",
  alignItems: "center",
  color: "#6A8490",
  margin: "auto",
  marginTop: "40px",
};

const dividerSx = {
  marginTop: "40px",
  marginBottom: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
};

const labelSx = {
  color: "#6A8490",
  fontSize: "14px",
  fontWeight: 400,
  paddingRight: "8px",
  paddingLeft: "8px",
};
// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const group = localStorage.getItem("group");

  const { logout } = useAuthContext();

  const { pathname } = useLocation();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = async () => {
    await logout();
    // localStorage.clear();
    // window.location.href = "/";
  };

  const renderSwitch = (group) => {
    switch (group) {
      case "موظف قضائي":
        return matterConfig;

      case "المحاسب":
        return accountConfig;

      case "مسؤول موارد بشرية":
        return hrConfig;

      case "مسؤول رئيسي":
        return navConfig;
    }
  };

  const renderContent = (
    <>
      <Stack
        //  spacing={3}
        sx={{
          // pt: 9,
          // pb: 2,
          // px: 2.5,
          // paddingLeft: "33px",

          paddingTop: "4px",
          flexShrink: 0,
        }}
      ></Stack>

      <NavSectionVertical data={renderSwitch(group)} />

      <Divider sx={{ ...dividerSx }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
        onClick={(e) => {
          navigate("/dashboard/personal-profile");
        }}
      >
        <img src="/assets/icons/navbar/avatar.svg" alt="" />

        <Typography sx={{ ...labelSx }}>الحساب الشخصي</Typography>
      </Box>

      <Button sx={{ ...logoutSx }} onClick={handleLogout}>
        تسجيل الخروج
      </Button>

      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD, paddingTop: "66px" },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              ...(isDesktop && {}),
              backgroundColor: "#CCDDE4",
              border: "1px solid none",
              filter: "drop-shadow(4px 4px 4px rgba(226, 226, 226, 0.25))",
              scrollbarWidth: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
