export const clientsColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الاسم", status: true },
  { id: "1", name: "رقم الهاتف", status: true },
  { id: "5", name: "البريد الالكتروني", status: true },
  { id: "2", name: "عدد القضايا", status: true },
  { id: "3", name: " الرصيد(KD)", status: true },
  { id: "4", name: "تاريخ الإضافة", status: true },
];

export const proceduresColumnsList = [
  { id: "0", name: "نوع الاجراء", status: true },
  { id: "1", name: "تاريخ الاجراء", status: true },
  { id: "2", name: "بيان الافادة", status: true },
  { id: "3", name: " الاجراء القادم", status: true },
  { id: "4", name: "رقم الاتصال", status: true },
  { id: "5", name: "مضاف من قبل", status: true },
];

export const CourtSessionColumnsList = [
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "الخصوم", status: true },
  { id: "2", name: "مستشارين القضية", status: true },
  { id: "3", name: "موضوع الدعوى", status: true },
  { id: "4", name: "الاجراء", status: false },
  { id: "5", name: "تاريخ الإجراء", status: true },
  { id: "6", name: "تاريخ الجلسة القادمة", status: false },
  { id: "7", name: " الموظف المختص", status: false },
  { id: "8", name: " الموظف البديل", status: false },
  { id: "9", name: "نوع الجلسة", status: false },
  { id: "10", name: "بيان الجلسة", status: true },
  { id: "11", name: "منطوق الحكم", status: true },
  { id: "12", name: "الحكم", status: false },
];

export const ExpertsSessionColumnsList = [
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "الخصوم", status: true },
  { id: "2", name: "مستشارين القضية", status: true },
  { id: "3", name: "موضوع الدعوى", status: true },
  { id: "4", name: "الاجراء", status: false },
  { id: "5", name: "تاريخ الإجراء", status: true },
  { id: "6", name: "تاريخ الجلسة القادمة", status: true },
  { id: "7", name: " الخبراء", status: false },
  { id: "8", name: " الموظف المختص", status: false },
  { id: "9", name: "نوع الجلسة", status: false },
  { id: "10", name: "بيان الجلسة", status: true },
  { id: "11", name: "منطوق الحكم", status: true },
  { id: "12", name: "الحكم", status: false },
  { id: "13", name: "نوع الحكم", status: false },
];

export const AdministrativeSessionColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "الخصوم", status: true },
  { id: "2", name: "مستشارين القضية", status: true },
  { id: "3", name: "موضوع الدعوى", status: true },
  /*{ id: "4", name: "الاجراء", status: false },*/
  { id: "5", name: "تاريخ الإجراء", status: true },
  { id: "6", name: "إنهاء الاجراء", status: false },
  { id: "7", name: " الموظف المختص", status: false },
  { id: "8", name: "نوع الاجراء", status: false },
  { id: "9", name: "حالة الإنجاز", status: false },
  { id: "10", name: "بيان الإجراء", status: true },
];

export const ExcuteSessionColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "الخصوم", status: true },
  { id: "2", name: "مستشارين القضية", status: true },
  { id: "3", name: "موضوع الدعوى", status: true },
  { id: "4", name: "تاريخ الإجراء", status: false },
  { id: "5", name: "انهاء المراجعة", status: false },
  { id: "6", name: "إجراء الإفادة", status: false },
  { id: "7", name: " رقم الاتصال", status: false },
  { id: "8", name: " حالة الإنجاز", status: false },
  { id: "9", name: "بيان الإجراء", status: true },
];

export const AttachmentsSessionColumnsList = [
  { id: "0", name: "اسم الملف", status: true },
  { id: "1", name: "مضاف من قبل", status: true },
  { id: "2", name: "نوع المرفق", status: true },
  { id: "3", name: "تاريخ المرفق", status: true },
  { id: "4", name: " تاريخ الإضافة", status: true },
];

export const collClientsColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الاسم", status: true },
  { id: "1", name: "رقم الهاتف", status: true },
  { id: "2", name: " الملاحظة", status: true },
  { id: "3", name: " الايميل", status: true },
];
export const oppColumnsList = [
  { id: "-1", name: "رقم الملف ", status: true },
  { id: "8", name: "رقم العقد ", status: true },
  { id: "0", name: "اسم الخصم", status: true },
  { id: "1", name: "حالة الملف", status: true },
  { id: "2", name: "مرحلة العمل", status: true },
  { id: "3", name: "رقم الهاتف", status: true },
  { id: "4", name: "قيمة المطالبة", status: true },
  { id: "5", name: "المبلغ المسدد", status: true },
  { id: "6", name: "الإسناد", status: true },
  { id: "7", name: "تاريخ الورود ", status: false },
  { id: "9", name: "محكمة التنفيذ", status: false },
  { id: "10", name: "سبب التنفيذ", status: false },
  { id: "11", name: "قيمة رسم الأداء", status: false },
  { id: "12", name: "رقم أمر الأداء", status: false },
  { id: "13", name: "المصروفات", status: false },
  { id: "14", name: "إجمالي المطالبة", status: false },
  { id: "15", name: "إجمالي الباقي", status: false },
  { id: "16", name: "الرقم الآلي", status: false },
  { id: "17", name: "العنوان", status: false },
  { id: "18", name: "المحافظة", status: false },
  { id: "19", name: "المدينة", status: false },
  { id: "20", name: "الجنسية", status: false },
  { id: "21", name: "الرقم المدني", status: false },
  { id: "22", name: "الرقم المفصول", status: false },
  { id: "23", name: " تاريخ نزول المحكمة", status: false },
];

export const invoicesColumnsList = [
  { id: "-1", name: "المسلسل", status: true },

  { id: "0", name: "الموكل ", status: true },
  { id: "1", name: "القيمة  ", status: true },
  { id: "2", name: "حالة الفاتورة", status: true },
  { id: "3", name: "الإصدار ", status: true },
];
export const voucherColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الموكل ", status: true },
  { id: "1", name: "القيمة  ", status: true },
  { id: "2", name: "الرقم الآلي", status: true },
  { id: "3", name: " تاريخ الإصدار", status: true },
];

export const reportsColumnsList = [
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "موضوع الدعوى", status: true },
  { id: "2", name: "المستشارين ضمن القضية", status: true },
  { id: "3", name: "الرقم الآلي", status: true },
  { id: "4", name: "المحكمة", status: true },
  { id: "5", name: "الإجراء", status: true },
  // { id: "6", name: "بيان الإجراء", status: true },
  { id: "7", name: "الجلسة القادمة", status: true },
  { id: "8", name: "المحامي", status: true },
];

export const usersColumnsList = [
  { id: "0", name: "الاسم", status: true },
  { id: "1", name: " المجموعة", status: true },
  { id: "2", name: "رقم الهاتف", status: true },
  { id: "3", name: "البريد الالكتروني", status: true },
  { id: "4", name: "ساعات العمل", status: true },
];

export const SessionsColumnsList = [
  { id: "0", name: "الإجراء", status: true },
  { id: "1", name: "تاريخ الإجراء", status: true },
  { id: "2", name: "تاريخ المراجعة", status: true },
  { id: "3", name: "الموكل", status: true },
  { id: "4", name: "الرقم الآلي", status: true },
  { id: "5", name: "الإسناد", status: true },
  { id: "6", name: "نوع الجلسة", status: true },
];

export const AccountsColumnsList = [
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "البيان", status: true },
  { id: "2", name: "ﺍﻟﻮﺻﻒ", status: true },
  { id: "3", name: "الرقم الآلي", status: true },
  { id: "4", name: "رقم العقد", status: true },
  //{ id: "2", name: "العملية", status: true },
  { id: "5", name: "التاريخ", status: true },
  { id: "6", name: "القيمة", status: true },
  { id: "7", name: "حالة الدفع", status: true },
];

export const genralExpenseColumnsList = [
  { id: "2", name: "نوع النفقة", status: true },
  { id: "0", name: "التاريخ ", status: true },
  { id: "1", name: "من قبل  ", status: true },
  { id: "3", name: "القيمة", status: true },
  { id: "4", name: "الوصف ", status: true },
];
export const lawColumnList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الموكل", status: true },
  { id: "10", name: "الكنية", status: true },
  { id: "1", name: "الإسناد", status: true },
  { id: "2", name: "نوع القضية", status: true },
  { id: "3", name: "حالة القضية", status: true },
  { id: "4", name: "تاريخ الورود", status: false },
  { id: "5", name: "تاريخ الإغلاق", status: false },
  { id: "6", name: "الرقم الآلي", status: false },
  { id: "7", name: "درجة القضية", status: false },
  { id: "8", name: "صفة القضية", status: false },
  { id: "9", name: "حساب التكلفة", status: false },
];

export const contractColumnList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الموكل", status: true },
  { id: "1", name: "مرتبط بـ", status: true },
  { id: "2", name: "نوع العقد", status: true },
  { id: "3", name: "بيان العقد", status: true },
  { id: "4", name: "تاريخ العقد", status: true },
  { id: "5", name: "نهاية العقد", status: true },
];

export const matterClientColumnList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "الإسناد", status: true },
  { id: "1", name: "نوع القضية", status: true },
  { id: "2", name: "حالة القضية", status: true },
  { id: "3", name: "تاريخ الورود", status: true },
  { id: "4", name: "تاريخ الإغلاق", status: true },
  { id: "5", name: "الرقم الآلي", status: false },
  { id: "6", name: "درجة القضية", status: false },
  { id: "7", name: "صفة القضية", status: false },
  { id: "8", name: "حساب التكلفة", status: false },
];

export const contractClientColumnList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "النوع", status: true },
  { id: "1", name: "تاريخ العقد", status: true },
  { id: "2", name: "بيان العقد", status: true },
  { id: "3", name: "الدفعة القادمة", status: true },
  { id: "4", name: "القضية", status: true },
];

export const invoicesClientColumnsList = [
  { id: "-1", name: "المسلسل", status: true },
  { id: "0", name: "القيمة  ", status: true },
  { id: "1", name: "حالة الفاتورة", status: true },
  { id: "2", name: "تاريخ الإصدار ", status: true },
  //
  { id: "3", name: "قيمة الحسم", status: false },
  { id: "4", name: "المتبقي  ", status: false },
  { id: "5", name: "الرقم الآلي  ", status: false },
];

export const ReportsColumnsList = [
  { id: "-1", name: "ﺍﺳﻢ ﺍﻟﻤﻮﻛﻞ", status: true },
  { id: "0", name: "ﻣﻮﺿﻮﻉ ﺍﻟﺪﻋﻮﻯ", status: false },
  { id: "1", name: "المستشار الاول", status: true },
  { id: "2", name: "ﺭﻗﻢ ﺍﻟﻘﻀﻴﺔ", status: true },
  { id: "3", name: "ﺍﻟﺮﻗﻢ ﺍﻵﻟﻲ ﻟﻠﻘﻀﻴﺔ", status: true },
  { id: "4", name: "ﺍﻟﻤﺤﻜﻤﺔ", status: false },
  { id: "5", name: "ﺍﻟﻘﺎﻋﺔ", status: false },
  { id: "6", name: "ﺗﺎﺭﻳﺦ ﺍﻟﻤﺮﺍﺟﻌﺔ", status: true },
  { id: "7", name: "ﺗﺎﺭﻳﺦ ﺍﻹﺟﺮﺍﺀ", status: true },
  { id: "8", name: "ﺑﻴﺎﻥ ﺍﻹﺟﺮﺍﺀ", status: false },
  { id: "9", name: "ﺍﻟﺴﺎﺩﺓ ﺍﻟﻤﺤﺎﻣﻴﻦ ﺍﻟﺤﻀﻮﺭ", status: true },
];
